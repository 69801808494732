import React, { useState } from 'react';
import DocumentButton from "./document-button"
import DownloadFileList from '../components/DownloadFileList';

export default function DocumentGroup({ name, documents }) {
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <div onClick={() => setExpanded(!expanded)}>
                <DocumentButton name={name} />
            </div>
            {expanded && <DownloadFileList documents={documents} />}
        </>
    )
}