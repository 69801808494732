import React from 'react'
import * as styles from "./DownloadFileList.module.css"

export default function DownloadFileList({ documents }) {
    documents.forEach(document => {
        if (document.name) {
            document.name = document.name.replace(/[#__-]/g, ' ')
        } else if (document.pavadinimas) {
            document.pavadinimas = document.pavadinimas.replace(/[#__-]/g, ' ')
        }
    })
    return (
        <div>
            {documents?.length > 0 && documents?.map((doc, i) =>
                <div className={styles.document} key={i}>
                    <div className={styles.documentName}>
                        {doc.pavadinimas || doc.name}
                    </div>
                    <div className={styles.download}>
                        <a href={`${process.env.GATSBY_API_URL}${doc.failas ? doc.failas[0].url : doc.url}`} download target="_blank">Atsisiųsti</a>
                    </div>
                </div>
            )}
        </div>
    )
}