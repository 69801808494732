import React from 'react'
import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore";

export default function DocumentButton({ name }) {
    return (
        <button className="documents-button">
            <div>
                {name}
            </div>
            < MdExpandMore style={{ paddingLeft: "15px" }} size={'30'} />
        </button>

    )
}