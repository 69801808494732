import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import * as styles from "./dokumentai.module.css"
import BasicLayoutStructure from "../components/BasicLayoutStructure";
import DocumentGroup from "../components/DocumentGroup";
import sortFunction from "../components/sort";

const Varzybos = () => {
  const data = useStaticQuery(query);
  let documentGroups = data
    .allStrapiVarzybos
    .group
    .sort((a, b) => sortFunction(a.edges[0].node.metai.prioritetas, b.edges[0].node.metai.prioritetas));

  return <Layout>
    <SEO
      seo={{
        metaTitle: "gimnastika.lt | Varžybos",
      }}
    />
    <BasicLayoutStructure header="Varžybos" left={<>
      {documentGroups.map((group, i) =>
        <DocumentGroup key={i}
          name={group.edges[0].node.metai.metai}
          documents={group.edges.map(doc => doc.node)} />)}
    </>} />
  </Layout>
};

export default Varzybos;

const query = graphql`
  query {
    allStrapiVarzybos(sort: {fields: created_at, order: DESC}) {
      group(field: metai___metai) {
        edges {
          node {
            pavadinimas
            failas {
              url
            }
            metai {
              metai
              prioritetas
            }
          }
        }
      }  
    }
  }
`